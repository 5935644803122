.detail-header {
  display: flex;
}

.tab-bar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: 1px solid #D9D9D9;
}

.default-tab-bar {
  margin-bottom: 0 !important;
}

.bookmark-btn {
  color: #1890ff;
  margin: .3rem;
}

.action-icon {
  font-size: 16px;
  margin: .3rem;
}

.text-filter {
  border-radius: 2px;
  align-self: end;
}

.audio-player-container {
  background: #F1F3F4;
  border-radius: 30px;
  padding: 14px;
}

.volume-slider {
  width: 80px;
}

.audio-element {
  flex-grow: 1;
}

.play-icon {
  background: transparent;
  border: none;
  box-shadow: none;
}

.text-block {
  display: inline-block;
  word-break: keep-all;
  white-space: normal;
}

.text-ruby {
  display: inline-flex;
  flex-direction: column;
}

.text-ruby rt {
  margin-bottom: .3rem;
  font-size: .6rem;
  text-align: center;
  white-space: nowrap;
}

.text-english {
  font-size: 1rem;
  padding: .5rem 2rem;
}

@media (max-width: 767px) {
  .filter-select {
    width: 150px;
  }

  .detail-header {
    gap: 8px;
    flex-direction: column;
  }

  .mb-w-full {
    width: 100%;
  }
}

.ant-upload-drag {
  border-radius: 8px !important;
}

.ant-upload-list {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 16px;
}