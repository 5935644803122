.search-container {
  padding: 16px 0;
  border-radius: 4px;
  min-height: 400px;
  gap: 0;
}

.dictionary .ant-input-search-button {
  height: 34px !important;
  border-radius: 4px;
}

.dictionary-label {
  padding-bottom: 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid #DDE1E6;
  padding-left: 16px;
}

.dictionary-category {
  background: #FAFAFA;
  padding: 4px 16px;
  border-bottom: 1px solid #DDE1E6;
}

.dictionary-result {
  border-bottom: 1px solid #DDE1E6;
  padding: 12px 16px;
  cursor: pointer;
}

.dictionary-container {
  border-radius: 8px;
  padding: .5rem;
}

.sound-icon {
  color: #007AFF;
  font-size: 1rem;
  cursor: pointer;
  margin-left: .5rem;
}

.meaning-container {
  border-top: 1px solid #DDE1E6;
  padding: .5rem 0;
}

.phrase-text {
  color: #00000073;
}

@media (max-width: 767px) {
  .search-container {
    padding: 12px 0;
  }
}