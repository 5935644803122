body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-regular {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.font-weight-light {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.height-100vh {
  height: 100vh;
}

.font-48 {
  font-size: 48px;
}

.font-24 {
  font-size: 24px;
}

.font-16 {
  font-size: 16px;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.bg-white {
  background: white;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.w-full {
  width: 100% !important;
}

.w-half {
  width: 50% !important;
}

.h-full {
  height: 100%;
}

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: 1rem !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

button {
  width: fit-content;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.text-white {
  color: white !important;
}

.text-gray {
  color: gray !important;
}

.text-red {
  color: #F5222D !important;
}

.text-yellow {
  color: #FFCC00 !important;
}

.text-blue {
  color: #007AFF !important;
}

.text-blue-2 {
  color: #1890FF !important;
}

.relative {
  position: relative;
}

.round-0 {
  border-radius: 0 !important;
}

.round-2 {
  border-radius: 2px !important;
}

.round-8 {
  border-radius: 8px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.noWrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-word !important;
}

.align-self-end {
  align-self: end !important;
}

.border-none {
  border: none !important;
}

.shadow-none {
  box-shadow: none !important;
}

.ant-image-preview-mask {
  display: none;
}

.sticky {
  position: sticky;
}

.container {
  padding: 1rem 104px;
  width: 100%;
}

.export-modal {
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  padding: 1rem 0;
}

.more {
  text-decoration: none;
  overflow: hidden;
  text-align: start;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body {
  scrollbar-width: 4px;
  scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@media (max-width: 767px) {
  .container {
    padding: 1rem;
  }
}
