.ai-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1000;
}

.ai-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  padding: 24px;
  overflow-y: auto;
}

.status-indicator {
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.status-indicator.connecting {
  background-color: #E6F7FF;
  color: #1890FF;
}

.status-indicator.connected {
  background-color: #F6FFED;
  color: #52C41A;
}

.status-indicator.error {
  background-color: #FFF1F0;
  color: #F5222D;
}

.status-indicator.disconnected {
  background-color: #FFF7E6;
  color: #FA8C16;
}

@media (max-width: 767px) {
  .ai-sidebar {
    width: 100%;
  }
}

.messages-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust based on status indicator height */
}

.message {
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 80%;
}

.message.user {
  background-color: #E6F7FF;
  align-self: flex-end;
}

.message.assistant {
  background-color: #F6FFED;
  align-self: flex-start;
} 