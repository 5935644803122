/* .ant-collapse-header {
  border-bottom: 1px solid #DDE1E6;
} */

.detail-set {
  display: flex;
  flex-wrap: wrap;
}

.word-popup {
  width: 200px;
}

.word-sound-icon {
  border: none;
  box-shadow: none;
  background: transparent;
  width: 1.5rem;
}

.word-action {
  border-top: 1px solid #DDE1E6;
  padding-top: 8px;
}

.phrase-tab {
  border-bottom: 1px solid #DDE1E6;
  padding: 1rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .detail-set {
    margin-bottom: .5rem;
  }
}