.header {
  background: black;
  padding: .5rem 1.5rem;
  position: sticky;
  top: 0;
  z-index: 99;
}

.menu-item {
  color: white;
  font-size: 16px;
}

.menu-authenticated {
  background: transparent;
  width: 500px;
}

.avatar {
  float: right;
  cursor: pointer;
}

.menu-collapsed {
  position: absolute;
  top: 4rem;
  left: 0;
  background: black;
  z-index: 9;
  width: 14rem;
  height: 500px;
}

.trigger {
  cursor: pointer;
}

.sub-header {
  border-bottom: 1px solid #D9D9D9;
}

.layout {
  min-height: 100vh;
}

.layout-content {
  min-height: 280px;
  background: #F0F2F5;
  height: 100%;
}

.library-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-actions {
  display: flex;
  justify-content: space-between;
}

.ant-menu-item-selected {
  background-color: black !important;
}

.ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-item-selected .ant-menu-title-content {
  color: #1677ff !important;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-item .ant-menu-title-content {
  color: white;
}
