.search-bar {
  border-radius: 2px;
  width: 262px;
}

.close-icon {
  position: absolute;
  right: 2.5rem;
  top: .5rem;
  opacity: .5;
  z-index: 10;
  cursor: pointer;
}

.require-icon {
  color: red;
  margin-right: .2rem;
}

@media (max-width: 768px) {
  .search-bar {
    width: 168px;
  }
}