.ant-tabs-tab {
  padding: .3rem 0 !important;
}

.ant-input-prefix {
  color: #1890FF;
}

.ant-space-item {
  width: 100%;
}

.form-header {
  border: 1px solid #D9D9D9;
  border-radius: .5rem;
  padding: 1.5rem;
  gap: 1rem;
  width: fit-content;
  margin-top: 6rem;
}

.auth-element {
  width: 312px;
  padding: 8px 12px;
  border-radius: 2px;
}