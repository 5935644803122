.app-card {
  padding: 6px 15px;
  border: 1px solid #D9D9D9;
  font-weight: 400;
  min-width: 156px;
  cursor: pointer;
}

.app-card.selected {
  border-color: black;
  font-weight: 700;
}

.account-element {
  padding: 4px 12px;
  border-radius: 2px;
}