.feed-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #D9D9D9;
  background: #FFF;
}

.feed-word {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  border-radius: 2px;
  border: 1px solid #D9D9D9;
}

.feed-option.selected {
  border: 1px solid #096DD9;
  color: #1890FF;
}

.feed-option.correct {
  border: 1px solid #52C41A;
  color: #52C41A;
}

.feed-option.incorrect {
  border: 1px solid #F5222D;
  color: #F5222D;
}

.success {
  border: 1px solid #B7EB8F;
  background: #F6FFED;
  color: #52C41A;
  border-radius: 2px;
  width: fit-content;
  gap: 8;
  padding: 1px 8px;
  height: 22px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.fail {
  border: 1px solid #FFA39E;
  background: #FFF1F0;
  color: #F5222D;
  border-radius: 2px;
  width: fit-content;
  gap: 8;
  padding: 1px 8px;
  height: 22px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.redo-icon {
  font-size: 24px;
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .trans-col {
    flex-direction: column;
    align-items: end;
  }
  
  .options-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    button {
      width: 100%;
    }
  }
}
